import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 278.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,278.000000) scale(0.100000,-0.100000)">



<path d="M1843 2587 c-29 -20 -32 -27 -33 -74 0 -47 3 -53 30 -70 30 -17 30
-18 29 -99 l-1 -82 -54 -53 -54 -53 0 -103 c0 -78 -3 -103 -13 -103 -19 0 -37
59 -37 122 0 46 -5 62 -26 88 -26 30 -28 31 -108 28 l-81 -3 -2 -445 -2 -444
-26 -14 c-23 -12 -26 -18 -23 -60 3 -40 7 -48 36 -65 32 -19 34 -19 68 -1 31
17 34 22 34 66 0 43 -3 49 -31 63 -20 11 -29 21 -25 31 3 9 6 83 6 165 l0 150
48 -3 47 -3 3 -68 c3 -63 2 -68 -23 -79 -14 -6 -25 -14 -26 -17 -7 -85 -6 -91
30 -112 33 -20 35 -20 68 -2 31 16 33 21 33 69 0 44 -3 52 -25 62 -23 10 -25
17 -25 72 0 33 2 60 5 60 2 0 25 -10 50 -22 l45 -22 0 -198 c0 -193 1 -198 20
-198 20 0 21 5 20 182 -1 120 2 184 9 186 18 6 137 -125 169 -186 48 -91 64
-160 61 -268 l-3 -95 288 -2 287 -2 -3 143 c-3 143 -3 144 -31 168 -24 21 -38
24 -104 24 -72 0 -76 1 -109 34 -50 50 -46 66 20 66 44 0 55 -3 59 -19 2 -11
19 -27 36 -36 30 -15 34 -15 64 2 28 17 32 25 35 69 2 47 1 50 -35 70 -37 21
-37 21 -70 2 -18 -11 -33 -26 -33 -33 0 -9 -20 -14 -66 -17 -63 -3 -67 -2 -80
24 -8 15 -14 42 -14 60 l0 33 165 0 c143 0 165 -2 165 -16 0 -15 51 -59 69
-59 5 0 25 9 44 20 32 19 35 24 32 66 -3 39 -8 49 -37 69 l-33 22 -37 -24
c-21 -13 -38 -28 -38 -33 0 -6 -58 -10 -150 -10 -82 0 -150 2 -150 5 0 3 16
19 35 36 41 36 54 84 33 123 -8 15 -61 70 -118 123 -98 89 -106 95 -140 90
-25 -3 -50 -19 -88 -56 -29 -28 -57 -51 -62 -51 -6 0 -10 -4 -10 -9 0 -6 9 -9
20 -8 11 1 20 5 20 10 0 4 4 6 9 2 5 -3 18 5 28 17 64 78 95 82 158 22 20 -19
38 -34 41 -34 12 0 113 -117 118 -136 4 -16 -4 -37 -24 -67 -16 -25 -30 -52
-30 -61 0 -9 -4 -16 -9 -16 -5 0 -16 -12 -26 -26 -15 -23 -15 -32 -5 -71 7
-24 22 -50 33 -58 13 -9 17 -20 13 -31 -10 -25 39 -93 79 -110 19 -8 59 -14
90 -14 46 0 60 -4 81 -25 24 -24 25 -29 22 -137 l-3 -113 -190 -3 c-104 -2
-218 0 -251 3 l-61 6 -5 102 c-3 56 -11 116 -18 132 -7 17 -17 41 -22 55 -23
57 -43 92 -75 129 -18 22 -33 44 -33 50 0 6 -4 10 -9 8 -5 -1 -17 7 -27 19
-18 20 -55 38 -81 39 -6 0 -20 9 -30 20 -29 33 -74 57 -94 52 -10 -3 -36 4
-56 14 -21 11 -50 18 -65 16 -16 -2 -29 2 -32 9 -8 21 -6 468 2 480 13 20 43
24 87 13 56 -14 64 -24 69 -85 6 -74 17 -124 26 -119 4 3 10 -1 13 -9 3 -8 16
-16 29 -18 14 -2 22 -9 20 -18 -2 -8 0 -12 4 -8 4 4 20 -2 35 -13 31 -22 119
-35 119 -18 0 6 5 7 10 4 6 -4 10 9 10 33 0 34 7 45 55 90 l55 51 -7 94 -6 93
32 13 c29 13 31 17 31 66 0 50 -2 54 -39 78 l-38 26 -37 -25 c-34 -23 -36 -28
-36 -77 0 -49 2 -54 33 -70 l32 -17 -5 -85 -5 -84 -54 -55 c-47 -47 -53 -58
-49 -86 6 -41 -7 -46 -65 -21 l-47 20 0 110 0 110 50 47 50 47 0 98 0 98 30
16 c28 14 30 20 30 68 0 50 -2 53 -40 75 -21 12 -40 22 -42 21 -2 0 -17 -11
-35 -22z m95 -75 c-2 -24 -11 -38 -30 -49 -24 -15 -28 -15 -52 0 -38 25 -37
81 1 101 25 13 31 12 56 -2 23 -14 27 -23 25 -50z m124 -144 c9 -7 18 -28 20
-46 4 -26 0 -37 -20 -52 -22 -18 -26 -18 -53 -4 -24 12 -29 21 -29 53 0 29 5
40 23 49 30 14 40 14 59 0z m653 -743 c33 -32 31 -59 -5 -85 -28 -20 -31 -20
-55 -4 -33 22 -35 76 -3 98 30 21 35 20 63 -9z m-180 -137 c36 -16 36 -78 0
-102 -24 -16 -27 -16 -55 4 -36 26 -39 61 -8 90 25 23 29 24 63 8z m-857 -27
c32 -28 30 -72 -6 -93 -24 -14 -30 -15 -55 -2 -37 20 -37 64 -1 93 32 25 33
25 62 2z m-137 -191 c26 -15 26 -71 -2 -95 -19 -18 -22 -18 -47 -3 -67 40 -20
134 49 98z"/>
<path d="M1862 2548 c-7 -7 -12 -19 -11 -27 0 -11 3 -10 9 4 5 11 9 14 9 6 1
-11 4 -11 16 -1 8 7 15 11 16 9 0 -2 2 -12 4 -22 5 -29 15 -33 15 -7 0 12 -8
29 -17 36 -22 17 -26 17 -41 2z"/>
<path d="M2013 2353 c-17 -6 -16 -45 1 -69 14 -18 15 -17 11 13 -4 35 10 52
28 34 8 -8 8 -14 -1 -23 -17 -17 -15 -33 3 -18 21 17 19 47 -4 60 -11 5 -21
10 -23 9 -2 0 -9 -3 -15 -6z"/>
<path d="M2663 1622 c-22 -14 -20 -64 3 -55 10 4 12 8 5 13 -8 4 -5 13 9 25
18 16 20 16 26 1 3 -9 1 -22 -5 -30 -6 -7 -7 -17 -3 -21 4 -5 -1 -5 -10 -2
-10 4 -18 2 -18 -3 0 -14 24 -12 38 2 34 34 -5 96 -45 70z"/>
<path d="M2486 1464 c-9 -8 -16 -22 -16 -30 0 -17 23 -44 38 -44 12 0 42 34
42 48 0 13 -28 42 -40 42 -5 0 -16 -7 -24 -16z m44 -30 c0 -22 -3 -23 -20 -14
-11 6 -20 18 -20 26 0 8 5 12 10 9 6 -3 10 -1 10 5 0 6 5 8 10 5 6 -3 10 -17
10 -31z"/>
<path d="M1622 1448 c-20 -20 -15 -66 8 -73 44 -14 71 53 31 75 -24 13 -24 13
-39 -2z"/>
<path d="M1488 1256 c-26 -19 -22 -54 7 -68 19 -8 28 -8 40 2 21 18 19 57 -4
70 -24 12 -22 12 -43 -4z m24 -3 c-10 -4 -9 -8 5 -19 15 -11 16 -15 4 -23 -20
-13 -41 9 -30 32 6 9 15 17 22 16 8 0 8 -2 -1 -6z"/>
<path d="M1240 2544 c-338 -41 -616 -164 -840 -372 l-55 -51 145 -145 144
-145 56 54 c156 153 368 242 626 261 l84 7 0 203 0 204 -22 -1 c-13 -1 -75 -7
-138 -15z m114 -40 c8 -20 9 -301 2 -313 -4 -5 -14 -10 -24 -9 -30 0 -176 -22
-207 -32 -16 -5 -41 -11 -55 -14 -69 -13 -203 -74 -284 -130 -32 -23 -68 -47
-80 -53 -11 -7 -17 -13 -11 -13 5 0 -8 -12 -29 -26 l-38 -26 -117 115 -116
115 20 24 c12 14 52 49 90 78 39 29 72 56 75 59 16 19 244 131 315 156 29 10
164 46 210 56 22 4 76 11 120 15 44 3 82 8 84 10 11 10 39 2 45 -12z"/>
<path d="M1300 2055 c-193 -36 -377 -110 -523 -211 -45 -31 -84 -59 -87 -61
-5 -5 252 -263 262 -263 4 0 24 12 45 26 100 68 254 129 358 142 44 5 60 11
60 22 -2 142 -19 360 -29 359 -6 0 -45 -7 -86 -14z m64 -89 c3 -28 8 -90 11
-137 7 -95 4 -102 -55 -114 -99 -20 -240 -76 -305 -119 -21 -15 -45 -26 -55
-26 -17 0 -210 190 -210 206 0 24 260 174 301 174 10 0 20 4 23 9 3 4 18 11
33 14 16 3 42 11 58 18 17 6 39 13 50 15 11 2 40 7 65 12 69 13 78 7 84 -52z"/>
<path d="M503 1709 c-123 -165 -205 -362 -243 -584 -11 -65 -19 -119 -18 -120
2 -2 66 -6 144 -10 l141 -7 12 77 c16 97 49 194 98 287 39 74 111 172 146 197
18 13 12 21 -94 127 -63 63 -116 114 -120 114 -3 0 -33 -37 -66 -81z m153 -51
c39 -40 74 -81 78 -91 4 -13 -4 -28 -24 -49 -50 -50 -162 -253 -175 -318 -17
-83 -40 -162 -48 -167 -17 -11 -180 -3 -195 9 -12 11 -11 37 8 123 22 103 44
182 56 205 12 22 36 77 38 90 1 3 15 32 31 65 42 86 129 204 150 205 6 0 42
-33 81 -72z"/>
<path d="M1370 1593 c-137 -17 -216 -47 -299 -113 l-54 -43 49 -45 c27 -25 63
-59 81 -75 l32 -30 30 26 c31 26 108 59 169 72 l32 7 0 98 c0 54 -3 100 -7
102 -5 2 -19 3 -33 1z m1 -57 c1 -6 3 -26 5 -43 4 -39 -11 -83 -28 -83 -18 0
-124 -44 -142 -59 -22 -18 -46 -7 -95 43 l-43 44 34 26 c88 67 265 115 269 72z"/>
<path d="M790 1475 c-81 -103 -132 -227 -163 -397 l-15 -78 109 -2 c60 -1 124
-2 141 -2 32 -1 33 0 40 54 9 74 40 155 87 228 l39 59 -92 92 c-50 50 -96 91
-101 91 -6 -1 -26 -21 -45 -45z m124 -75 c62 -64 66 -71 55 -92 -7 -13 -15
-25 -18 -28 -8 -6 -56 -116 -64 -145 -3 -11 -11 -39 -17 -62 l-11 -43 -84 0
c-104 0 -115 4 -115 42 0 29 23 123 40 163 4 11 18 46 30 78 29 74 87 156 111
157 3 0 36 -31 73 -70z"/>
<path d="M1043 1223 c-32 -61 -57 -134 -68 -195 l-6 -38 39 0 39 0 13 58 c7
31 23 85 37 119 24 61 24 62 6 87 -10 14 -21 26 -24 26 -3 0 -20 -26 -36 -57z"/>
<path d="M1733 1138 c-30 -18 -33 -24 -33 -68 0 -46 2 -49 41 -72 l42 -25 38
25 c36 24 39 29 39 73 0 43 -3 49 -36 68 -42 25 -50 25 -91 -1z m78 -18 c14
-8 19 -21 19 -51 0 -32 -4 -41 -25 -51 -19 -8 -31 -8 -50 0 -21 10 -25 19 -25
51 0 54 35 75 81 51z"/>
<path d="M1780 1114 c0 -5 6 -21 14 -35 13 -25 12 -26 -15 -24 -33 2 -38 -10
-10 -24 14 -8 22 -6 35 8 21 24 20 44 -4 66 -11 10 -20 14 -20 9z"/>
<path d="M1835 927 c-58 -15 -115 -46 -115 -63 0 -6 7 -18 17 -25 9 -8 24 -31
34 -53 16 -36 18 -37 28 -18 18 33 91 56 166 50 93 -8 138 -39 159 -110 4 -15
17 -18 71 -18 74 0 77 4 51 78 -23 65 -56 101 -123 134 -52 25 -77 31 -150 34
-51 2 -109 -2 -138 -9z m243 -39 c31 -11 70 -32 86 -46 30 -28 68 -101 60
-114 -8 -13 -52 -9 -63 5 -61 75 -75 86 -125 102 -64 20 -157 16 -206 -9 -35
-17 -45 -15 -60 14 -31 58 180 90 308 48z"/>
<path d="M747 923 c-3 -5 -32 -69 -65 -143 -33 -74 -106 -236 -162 -360 -56
-124 -104 -231 -107 -238 -4 -10 12 -12 76 -10 l82 3 57 135 c32 74 87 204
122 287 35 84 67 149 71 145 3 -4 30 -63 59 -132 29 -69 57 -129 62 -134 4 -6
8 -16 8 -23 0 -11 -22 -13 -107 -8 -72 3 -101 2 -88 -4 11 -5 29 -10 40 -10
39 -2 125 -50 168 -94 24 -25 58 -72 76 -106 l32 -61 85 0 c46 0 84 1 84 3 0
1 -19 42 -43 92 -24 50 -102 219 -173 377 l-129 287 -72 1 c-39 0 -74 -3 -76
-7z m150 -83 c56 -112 93 -202 87 -212 -4 -6 -3 -8 4 -4 6 3 14 -4 18 -16 4
-13 20 -50 36 -83 40 -83 128 -293 128 -306 0 -6 -16 -9 -41 -7 -39 3 -44 7
-79 65 -21 34 -40 59 -44 56 -3 -3 -6 -1 -6 7 0 7 -14 25 -32 40 -32 28 -32
29 -13 50 27 30 17 63 -78 270 -16 35 -55 70 -70 64 -21 -8 -157 -291 -157
-327 0 -9 -6 -22 -12 -29 -7 -7 -14 -20 -16 -28 -2 -8 -12 -33 -23 -56 -10
-24 -19 -47 -19 -51 0 -18 -47 -61 -72 -67 -33 -8 -46 22 -25 56 8 13 18 34
22 48 4 14 13 32 20 40 7 8 22 42 34 75 13 33 27 65 32 71 5 6 15 28 23 50 7
21 38 91 68 154 30 63 59 126 63 140 13 44 25 51 79 48 48 -3 51 -5 73 -48z"/>
<path d="M1066 778 l7 -152 64 -135 c34 -75 63 -141 63 -148 0 -6 5 -15 12
-19 8 -5 9 51 4 229 l-7 237 139 0 c158 0 196 -10 235 -62 35 -48 47 -92 47
-174 l0 -74 75 0 75 0 0 63 c0 199 -73 323 -218 367 -56 17 -93 20 -284 20
l-219 0 7 -152z m459 107 c28 -4 66 -18 86 -31 45 -28 113 -109 104 -124 -3
-5 -2 -10 3 -10 6 0 15 -28 21 -63 18 -106 13 -134 -26 -141 -33 -7 -44 9 -54
75 -14 98 -16 105 -35 135 -49 77 -94 95 -252 99 -73 2 -133 -1 -147 -7 -38
-17 -45 -51 -44 -190 0 -71 -1 -131 -3 -133 -2 -2 -14 17 -27 43 -13 26 -28
56 -34 67 -7 11 -13 79 -15 152 l-4 132 39 3 c74 6 339 2 388 -7z"/>
<path d="M1818 593 c2 -37 9 -71 15 -74 5 -4 34 -9 65 -13 111 -13 207 -37
230 -59 28 -26 30 -86 4 -110 -72 -66 -284 -62 -340 5 -7 9 -16 35 -20 58 l-7
41 -74 2 -73 2 5 -43 c17 -154 153 -239 366 -230 186 8 290 92 291 236 0 61
-17 99 -59 135 -44 36 -99 55 -246 81 -71 13 -137 26 -146 30 -15 7 -16 0 -11
-61z m154 1 c159 -26 231 -58 265 -118 19 -32 16 -112 -6 -154 -40 -79 -110
-114 -243 -120 -189 -9 -310 58 -334 185 -3 17 1 24 20 29 38 9 64 -4 76 -40
11 -35 49 -75 89 -96 40 -20 151 -30 196 -18 22 6 43 11 48 10 4 -1 28 18 52
43 58 58 62 100 12 150 -32 32 -54 38 -252 70 -37 6 -62 40 -44 61 14 17 9 17
121 -2z"/>
<path d="M1570 354 c-30 -35 -75 -44 -216 -44 -74 0 -134 -3 -132 -7 2 -5 17
-37 33 -72 l30 -64 128 6 c108 5 138 11 183 31 30 14 54 28 54 31 0 4 -9 20
-20 36 -12 15 -26 45 -33 65 -12 33 -14 35 -27 18z m12 -71 c31 -28 24 -42
-32 -61 -8 -3 -67 -7 -131 -9 l-115 -4 -12 26 c-7 14 -12 30 -12 34 0 5 48 9
108 10 59 0 121 5 137 10 17 5 32 10 34 10 3 1 13 -6 23 -16z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
